import { useEffect } from "react";
import { Collapse, PullToRefresh } from "antd-mobile";
import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { getParticipantResultsRequest } from "@/services/qr/participant.service";
import { ParticipantResultDto } from "@/services/qr/dto/participant/participant.dto";
import { getEventRequest } from "@/services/qr/event.service";

import ParticipantInfo from "@/pages/results/components/ParticipantInfo";
import ContestInfo from "@/pages/results/components/ContestInfo";
import SegmentTable from "@/pages/results/components/SegmentTable";
import LoadingScreen from "@/components/LoadingScreen";
import ErrorResult from "@/components/ErrorResult";

import { useHeader } from "@/context/HeaderContext";

import { TitleCollapse } from "@/components/TitleCollapse";
import { GenderEnum, translateGender } from "@/enums/gender.enum";

const TitleError = ({ number }: any) => (
  <p>
    No se encontraron resultados para el participante <strong>{number}</strong>
  </p>
);

export default function ParticipantResults() {
  const { setTitle, setBack, setLive, resetContext } = useHeader();
  const { prefix, number } = useParams<{ prefix: string; number: string }>();

  const {
    loading: loadingParticipant,
    run: runParticipant,
    error: errorParticipant,
    data: results,
  } = useRequest(() => getParticipantResultsRequest(prefix!!, number!!), {
    onError: () => console.error("Error fetching results"),
    manual: true,
  });

  useEffect(() => {
    if (prefix && number) {
      runParticipant();
    }
  }, [prefix, number, runParticipant]);

  const { data: event } = useRequest(() => getEventRequest(prefix!!), {
    onError: () => console.error("Error fetching event"),
  });

  const firstResult = results?.find(() => true) as ParticipantResultDto;

  useEffect(() => {
    setTitle(event?.name ?? "");

    if ((results?.length ?? 0) === 1)
      setBack(
        event ? `/${prefix}/grupo-por-edad/${firstResult.age_group_uuid}` : "/"
      );
    else setBack(event ? `/${prefix}/` : "/");

    setLive(event?.live ?? false);

    return () => {
      resetContext();
    };
  }, [
    prefix,
    setTitle,
    setBack,
    resetContext,
    setLive,
    event,
    results,
    firstResult,
  ]);

  if (!results?.length && errorParticipant)
    return <ErrorResult title={<TitleError number={number} />} />;
  if (loadingParticipant || !results?.length) return <LoadingScreen />;
  if (errorParticipant)
    return <ErrorResult title={<TitleError number={number} />} />;

  return (
    <>
      <PullToRefresh
        onRefresh={async () => {
          runParticipant();
        }}
      >
        <div className="bg-white dark:bg-gray-800 rounded-lg mx-auto pb-2">
          {results.length === 1 ? (
            <>
              <div className="sm:flex flex-row p-4 pt-4">
                <ParticipantInfo result={firstResult} />
                <ContestInfo result={firstResult} />
              </div>
              <SegmentTable result={firstResult} />
            </>
          ) : (
            <>
              <div className="sm:flex flex-row p-4 pb-0 pt-2">
                <ParticipantInfo result={firstResult} useInitials={true} />
              </div>
              <Collapse accordion>
                {results.map((result, index) => (
                  <Collapse.Panel
                    key={index.toString()}
                    title={
                      <TitleCollapse
                        title={`${result.contest_name} ${
                          result.age_group_name
                        } ${translateGender(
                          result.participant_gender as GenderEnum
                        )}`}
                      />
                    }
                  >
                    <>
                      <div className="sm:flex flex-row p-4 pt-0">
                        <ContestInfo result={result} />
                      </div>
                      <SegmentTable result={result} disablePadding={true} />
                    </>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </>
          )}
        </div>
      </PullToRefresh>
      <div className="mb-9" />
    </>
  );
}
