import { useEffect, useState, useCallback } from "react";
import { List, PullToRefresh, Tag } from "antd-mobile";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useRequest } from "ahooks";
import { getAgeGroupResultsRequest } from "@/services/qr/age_group.service";
import { getEventRequest } from "@/services/qr/event.service";

import ErrorResult from "@/components/ErrorResult";
import LoadingScreen from "@/components/LoadingScreen";
import { ParticipantResultDto } from "@/services/qr/dto/participant/participant.dto";
import { useHeader } from "@/context/HeaderContext";
import TimeComponent from "@/components/TimeComponent";

import { TitleCollapse } from "@/components/TitleCollapse";
import Avatar from "@/components/Avatar";
import { GenderEnum, translateGender } from "@/enums/gender.enum";
import { CustomInfiniteScroll } from "@/components/CustomInfiniteScroll";

export default function ParticipantResults() {
  const navigate = useNavigate();
  const { setTitle, setBack, setLive, resetContext } = useHeader();
  const [results, setResults] = useState<ParticipantResultDto[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const { prefix, age_group_uuid } = useParams<{
    prefix: string;
    age_group_uuid: string;
  }>();

  // Usar useLocation para obtener los parámetros de URL de forma reactiva
  const location = useLocation();
  const isFromGroupedContest =
    new URLSearchParams(location.search).get("grouped") === "true";

  const {
    loading: loadingAgeGroup,
    run: runAgeGroup,
    error: errorAgeGroup,
  } = useRequest(
    () => getAgeGroupResultsRequest(age_group_uuid!!, lastEvaluatedKey),
    {
      onSuccess: (data) => {
        setResults((prevResults) => {
          const allResults = [...prevResults, ...data.results];
          const uniqueResults = allResults.filter(
            (result, index, self) =>
              index === self.findIndex((r) => r.number === result.number)
          );
          return uniqueResults;
        });

        if (data.last_evaluated_key) {
          setLastEvaluatedKey(data.last_evaluated_key);
        }

        setHasMore(!!data.last_evaluated_key);
        setIsLoadingMore(false);
      },
      onError: (error) => {
        console.error("Error fetching events:", error);
        setHasMore(false);
        setIsLoadingMore(false);
      },
    }
  );

  const { run: runEvent, data: event } = useRequest(getEventRequest, {
    manual: true,
    onError: () => console.error("Error fetching event"),
  });

  useEffect(() => {
    if (prefix) {
      runEvent(prefix);
    }
  }, [prefix, runEvent]);

  const firstResult = results?.find(() => true) as ParticipantResultDto;

  useEffect(() => {
    if (firstResult) {
      // Si la carrera viene de un grupo, mostrar solo el nombre de la categoría
      // De lo contrario, mostrar el nombre completo (carrera + categoría + género)
      if (isFromGroupedContest) {
        setTitle(
          `${firstResult?.age_group_name} ${translateGender(
            firstResult.participant_gender as GenderEnum
          )}`
        );
      } else {
        setTitle(
          `${firstResult?.contest_name} ${
            firstResult?.age_group_name
          } ${translateGender(firstResult.participant_gender as GenderEnum)}`
        );
      }
    }

    if (event) setBack(event ? `/${prefix}` : "/");

    setLive(event?.live ?? false);

    return () => {
      resetContext();
    };
  }, [
    prefix,
    setTitle,
    setBack,
    resetContext,
    setLive,
    event,
    firstResult,
    isFromGroupedContest,
  ]);

  // Función para cargar más resultados
  const loadMore = useCallback(() => {
    if (!loadingAgeGroup && !isLoadingMore && hasMore) {
      setIsLoadingMore(true);
      runAgeGroup();
    }
  }, [loadingAgeGroup, isLoadingMore, hasMore, runAgeGroup]);

  const handleClick = (participant: ParticipantResultDto) => {
    navigate(`/${prefix}/${participant.number}`);
  };

  if (!results?.length && errorAgeGroup) return <ErrorResult />;
  if (loadingAgeGroup && !isLoadingMore && !results?.length)
    return <LoadingScreen />;
  if (errorAgeGroup) return <ErrorResult />;

  return (
    <PullToRefresh
      onRefresh={async () => {
        setResults([]);
        setLastEvaluatedKey(null);
        setIsLoadingMore(false);
        runAgeGroup();
      }}
    >
      <CustomInfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        isLoading={isLoadingMore}
        threshold={300}
      >
        <List>
          {results?.map((participant) => {
            return (
              <List.Item
                key={participant.number}
                description={<TimeComponent time={participant.total_time} />}
                prefix={
                  <Avatar
                    title={participant.place_age_group.toString()}
                    circle={participant.percent_completed}
                    color={participant.participant_gender}
                    dot={participant.percent_completed < 100}
                    size="small"
                    titleSize="16px"
                  />
                }
                onClick={() => handleClick(participant)}
              >
                <TitleCollapse
                  title={
                    <div className="flex items-center gap-2">
                      <span className="truncate">
                        {participant.participant_name}
                      </span>
                      <Tag className="dark:text-gray-800 shrink-0">
                        {participant.number}
                      </Tag>
                    </div>
                  }
                />
              </List.Item>
            );
          })}
        </List>
      </CustomInfiniteScroll>
    </PullToRefresh>
  );
}
