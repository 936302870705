import React from "react";
import { ConfigProvider } from "antd-mobile";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { HeaderProvider } from "@/context/HeaderContext";
import { ThemeProvider, useTheme } from "@/context/ThemeContext";
import Header from "./layout/header";
import Footer from "./layout/footer";
import ThemeMetaTags from "@/components/ThemeMetaTags";

import Home from "@/pages/events";
import AgeGroups from "@/pages/age_groups/index";
import Participants from "@/pages/participants/index";
import Results from "@/pages/results/index";
import esUS from "antd-mobile/es/locales/es-ES";
import ScrollToTop from "@/components/ScrollTo";
import "./styles/dark.css";

const { REACT_APP_TITLE } = process.env;

const AppContent: React.FC = () => {
  const { theme } = useTheme();

  React.useEffect(() => {
    document.documentElement.dataset.prefersColorScheme = theme;
  }, [theme]);

  return (
    <ConfigProvider locale={esUS}>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:prefix" element={<AgeGroups />} />
          <Route
            path="/:prefix/grupo-por-edad/:age_group_uuid"
            element={<Participants />}
          />
          <Route path="/:prefix/:number" element={<Results />} />
        </Routes>
        <Footer />
      </Router>
    </ConfigProvider>
  );
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <HeaderProvider>
          <ThemeMetaTags />
          <AppContent />
        </HeaderProvider>
      </ThemeProvider>
      <Helmet>
        <title>{REACT_APP_TITLE}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default App;
