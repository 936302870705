import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@/context/ThemeContext';

const ThemeMetaTags: React.FC = () => {
  const { theme } = useTheme();

  useEffect(() => {
    // Actualizar directamente los meta tags como respaldo
    const themeColor = theme === 'dark' ? '#111827' : '#ffffff';
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', themeColor);
    }

    const metaStatusBar = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
    if (metaStatusBar) {
      metaStatusBar.setAttribute('content', theme === 'dark' ? 'black' : 'default');
    }
  }, [theme]);

  return (
    <Helmet>
      <meta name="theme-color" content={theme === 'dark' ? '#111827' : '#ffffff'} />
      <meta name="apple-mobile-web-app-status-bar-style" content={theme === 'dark' ? 'black' : 'default'} />
    </Helmet>
  );
};

export default ThemeMetaTags;
