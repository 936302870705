export enum GenderEnum {
  MALE = "male",
  FEMALE = "female",
  RELAY = "relay",
  MIXED = "mixed"
}

export enum GenderEnumES {
  MALE = "Masculino",
  FEMALE = "Femenino",
  RELAY = "Relevo",
  MIXED = "Mixto"
}

export function translateGender(gender: GenderEnum): string {
  switch (gender) {
    case GenderEnum.MALE:
      return GenderEnumES.MALE;
    case GenderEnum.FEMALE:
      return GenderEnumES.FEMALE;
    case GenderEnum.RELAY:
      return GenderEnumES.RELAY;
    case GenderEnum.MIXED:
      return GenderEnumES.MIXED;
    default:
      return gender; // Si no se encuentra, se devuelve el valor original
  }
}
