import React from "react";

interface TimeComponentProps {
  time: string;
  textSize?: "xs" | "xxs"; // Solo se permiten 'xs' y 'xxs'
  color?: string;
}

const TimeComponent: React.FC<TimeComponentProps> = ({
  time,
  textSize = "xxs", // Valor por defecto 'xxs',
  color = "",
}) => {
  const [timeMain, timeMs] = time.split(".");

  const textSizeStyle = textSize === "xs" ? "12px" : "10px"; // 'xs' para 12px y 'xxs' para 10px

  return (
    <div style={{ color }}>
      {timeMain}
      <span style={{ fontSize: textSizeStyle, verticalAlign: "baseline" }}>
        .{timeMs}
      </span>
    </div>
  );
};

export default TimeComponent;
