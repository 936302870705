import React from "react";
import { Footer } from "antd-mobile";

const { REACT_APP_TITLE } = process.env;

const FooterCustom: React.FC = () => {
  return (
    <Footer
      content={
        <div className="dark:text-gray-600 text-gray-400">
          Power by {REACT_APP_TITLE}
        </div>
      }
    />
  );
};

export default FooterCustom;
