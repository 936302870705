import { ApiRequest } from "@/services/request";
import {
  ParticipantResultDto,
  ParticipantSearchDto,
} from "@/services/qr/dto/participant/participant.dto";

export function getParticipantResultsRequest(
  prefix: string,
  number: string
): Promise<ParticipantResultDto[]> {
  return ApiRequest(`/participant/${prefix}/${number}`, {
    method: "GET",
  });
}

export function searchParticipantResultsRequest(
  prefix: string,
  query: string
): Promise<ParticipantSearchDto[]> {
  return ApiRequest(`/search/participant/${prefix}`, {
    method: "GET",
    params: {
      query: query,
    },
  });
}
