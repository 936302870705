import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { List, PullToRefresh, Steps } from "antd-mobile";
import { useRequest } from "ahooks";
import { getEventRequest } from "@/services/qr/event.service";
import { useHeader } from "@/context/HeaderContext";
import LoadingScreen from "@/components/LoadingScreen";
import ErrorResult from "@/components/ErrorResult";
import { AgeGroupDto, ContestDto } from "@/services/qr/dto/event/event.dto";
import { GenderEnum, translateGender } from "@/enums/gender.enum";
import GenderBadge from "@/components/GenderBadge";

interface GroupedContest {
  name: string;
  contests: ContestDto[];
  genders: GenderEnum[];
}

const AgeGroupList: React.FC = () => {
  const navigate = useNavigate();
  const { setTitle, setBack, setLive, resetContext } = useHeader();
  const { prefix } = useParams<{ prefix: string }>();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedContest, setSelectedContest] = useState<string>();
  const [selectedGender, setSelectedGender] = useState<GenderEnum>();

  const { run, data, loading, error } = useRequest(
    () => getEventRequest(prefix!!),
    {
      manual: true,
      onError: () => console.error("Error fetching event"),
    }
  );

  // Agrupar carreras basándose en similitud de nombres y géneros asociados
  const groupedContests = useMemo(() => {
    if (!data?.contests) return [];

    // Primero, agrupamos por nombre exacto para identificar posibles duplicados
    const initialGroups: Record<string, ContestDto[]> = {};
    data.contests.forEach((contest) => {
      const name = contest.name;
      if (!initialGroups[name]) {
        initialGroups[name] = [];
      }
      initialGroups[name].push(contest);
    });

    // Ahora, intentamos agrupar por género
    const result: GroupedContest[] = [];
    const processed = new Set<string>();

    // Función para encontrar la parte común más larga entre dos cadenas
    const findCommonPrefix = (str1: string, str2: string): string => {
      const minLength = Math.min(str1.length, str2.length);
      let i = 0;
      while (i < minLength && str1[i].toLowerCase() === str2[i].toLowerCase()) {
        i++;
      }
      if (i >= 3) {
        while (i > 0 && str1[i - 1] !== " ") {
          i--;
        }
        if (i === 0) {
          return "";
        }
        return str1.substring(0, i).trim();
      }
      return "";
    };

    // Función para determinar si dos nombres podrían ser variantes del mismo
    const areSimilarNames = (name1: string, name2: string): boolean => {
      const normalized1 = name1.toLowerCase().replace(/\s+/g, " ").trim();
      const normalized2 = name2.toLowerCase().replace(/\s+/g, " ").trim();
      if (normalized1 === normalized2) return true;
      const commonPart = findCommonPrefix(normalized1, normalized2);
      if (
        commonPart &&
        commonPart.length >=
          Math.min(normalized1.length, normalized2.length) * 0.6
      ) {
        return true;
      }
      const genderTerms = [
        "masculino",
        "varonil",
        "hombres",
        "varones",
        "femenino",
        "femenil",
        "mujeres",
        "damas",
        "mixto",
        "relevo",
      ];
      let stripped1 = normalized1;
      let stripped2 = normalized2;
      genderTerms.forEach((term) => {
        // Se ha modificado la regex para eliminar escapes innecesarios (colocando el guion al inicio)
        const regex = new RegExp(`[- ]*(${term})[- ]*`, "gi");
        stripped1 = stripped1.replace(regex, " ");
        stripped2 = stripped2.replace(regex, " ");
      });
      stripped1 = stripped1.replace(/\s+/g, " ").trim();
      stripped2 = stripped2.replace(/\s+/g, " ").trim();
      return (
        stripped1 === stripped2 ||
        (stripped1.includes(stripped2) && stripped2.length > 3) ||
        (stripped2.includes(stripped1) && stripped1.length > 3)
      );
    };

    // Función para obtener un nombre común para un grupo de nombres similares
    const getCommonName = (names: string[]): string => {
      if (names.length === 0) return "";
      if (names.length === 1) return names[0];
      let commonName = names[0];
      for (let i = 1; i < names.length; i++) {
        const prefix = findCommonPrefix(commonName, names[i]);
        if (prefix) {
          commonName = prefix;
        }
      }
      return commonName.trim() || names[0];
    };

    Object.entries(initialGroups).forEach(([name, contests]) => {
      if (processed.has(name)) return;
      if (contests.length === 1) {
        const contest = contests[0];
        const relatedContests: ContestDto[] = [contest];
        const allGenders = [...contest.genders];
        Object.entries(initialGroups).forEach(([otherName, otherContests]) => {
          if (name === otherName) return;
          if (processed.has(otherName)) return;
          if (areSimilarNames(name, otherName)) {
            otherContests.forEach((otherContest) => {
              relatedContests.push(otherContest);
              otherContest.genders.forEach((gender) => {
                if (!allGenders.includes(gender)) {
                  allGenders.push(gender);
                }
              });
            });
            processed.add(otherName);
          }
        });
        processed.add(name);
        const contestNames = relatedContests.map((c) => c.name);
        const displayName = getCommonName(contestNames);
        result.push({
          name: displayName,
          contests: relatedContests,
          genders: allGenders,
        });
      } else {
        const allGenders: GenderEnum[] = [];
        contests.forEach((contest) => {
          contest.genders.forEach((gender) => {
            if (!allGenders.includes(gender)) {
              allGenders.push(gender);
            }
          });
        });
        processed.add(name);
        result.push({
          name,
          contests,
          genders: allGenders,
        });
      }
    });
    return result;
  }, [data?.contests]);

  useEffect(() => {
    if (prefix) {
      run();
    }
  }, [prefix, run]);

  // Función para navegar a la categoría correspondiente
  const handleClick = useCallback(
    (ageGroup: AgeGroupDto) => {
      const isFromGroupedContest = groupedContests.some(
        (group) =>
          group.contests.length > 1 &&
          group.contests.some((contest) =>
            contest.age_groups.some((ag) => ag.uuid === ageGroup.uuid)
          )
      );
      navigate(
        `/${prefix}/grupo-por-edad/${ageGroup.uuid}?grouped=${isFromGroupedContest}`
      );
    },
    [groupedContests, navigate, prefix]
  );

  // Función para manejar la selección de carrera
  const handleContestSelect = useCallback(
    (groupName: string) => {
      const group = groupedContests.find((g) => g.name === groupName);
      if (!group) return;
      if (group.contests.length === 1) {
        const contest = group.contests[0];
        setSelectedContest(contest.uuid);
        if (contest.genders.length === 1) {
          setSelectedGender(contest.genders[0]);
          const filteredAgeGroups = contest.age_groups.filter(
            (group) => group.gender === contest.genders[0]
          );
          if (filteredAgeGroups.length === 1) {
            handleClick(filteredAgeGroups[0]);
            return;
          }
          setCurrentStep(2);
          return;
        }
        setCurrentStep(1);
        return;
      }
      setSelectedContest(group.contests[0].uuid);
      setCurrentStep(1);
    },
    [groupedContests, handleClick]
  );

  // Función de auto-selección para cuando solo hay una carrera disponible
  const autoSelectSingleContest = useCallback(() => {
    if (groupedContests.length === 1 && !selectedContest && data) {
      console.log("Auto-seleccionando la única carrera disponible");
      const group = groupedContests[0];
      handleContestSelect(group.name);
    }
  }, [groupedContests, selectedContest, data, handleContestSelect]);

  useEffect(() => {
    const timer = setTimeout(() => {
      autoSelectSingleContest();
    }, 100);
    return () => clearTimeout(timer);
  }, [groupedContests.length, selectedContest, data, autoSelectSingleContest]);

  useEffect(() => {
    setTitle(data?.name ?? "");
    setBack(`/`);
    setLive(data?.live ?? false);
    return () => {
      resetContext();
    };
  }, [setTitle, setBack, resetContext, setLive, data]);

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.textContent = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  // Función para manejar la selección de género
  const handleGenderSelect = useCallback(
    (gender: GenderEnum) => {
      setSelectedGender(gender);
      const selectedContestData = data?.contests?.find(
        (contest) => contest.uuid === selectedContest
      );
      if (!selectedContestData) return;
      console.log("Contest seleccionado:", selectedContestData);
      console.log("Género seleccionado:", gender);
      console.log(
        "Grupos de edad disponibles:",
        selectedContestData.age_groups
      );
      selectedContestData.age_groups.forEach((group) => {
        console.log(
          `Grupo: ${group.name}, Género del grupo: ${group.gender}, Género seleccionado: ${gender}`
        );
      });
      const originalContest = data?.contests?.find(
        (contest) =>
          contest.genders.includes(gender) &&
          contest.name.includes(selectedContestData.name.split(" ")[0])
      );
      console.log("Contest original encontrado:", originalContest);
      if (originalContest) {
        const filteredAgeGroups = originalContest.age_groups.filter(
          (group) => group.gender === gender
        );
        console.log(
          "Grupos filtrados desde contest original:",
          filteredAgeGroups
        );
        if (filteredAgeGroups.length === 1) {
          handleClick(filteredAgeGroups[0]);
          return;
        }
        setCurrentStep(2);
        return;
      }
      const filteredAgeGroups = selectedContestData.age_groups.filter(
        (group) => group.gender === gender
      );
      console.log(
        "Grupos filtrados por coincidencia directa:",
        filteredAgeGroups
      );
      if (filteredAgeGroups.length === 1) {
        handleClick(filteredAgeGroups[0]);
        return;
      }
      setCurrentStep(2);
    },
    [data, selectedContest, handleClick]
  );

  useEffect(() => {
    const selectedContestData = data?.contests?.find(
      (contest) => contest.uuid === selectedContest
    );
    if (selectedContestData && selectedGender) {
      const filteredAgeGroups = selectedContestData.age_groups.filter(
        (group) => group.gender === selectedGender
      );
      if (filteredAgeGroups.length === 1) {
        handleClick(filteredAgeGroups[0]);
      }
    }
  }, [selectedContest, selectedGender, data, handleClick]);

  // Condiciones para mostrar Loading o Error
  if (loading) return <LoadingScreen />;
  if (error) return <ErrorResult />;
  if (!data) return <LoadingScreen />;

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className="px-4">
            <h3 className="text-lg font-medium mb-4 text-gray-900 dark:text-white">
              Selecciona tu carrera
            </h3>
            <div className="space-y-2">
              {groupedContests.map((group) => (
                <button
                  key={group.name}
                  onClick={() => handleContestSelect(group.name)}
                  className={`w-full p-4 text-left rounded-lg transition-colors ${
                    group.contests.some((c) => c.uuid === selectedContest)
                      ? "bg-blue-500 text-white"
                      : "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                  }`}
                >
                  <div className="font-medium text-base">{group.name}</div>
                </button>
              ))}
            </div>
          </div>
        );
      case 1: {
        const selectedGroup = groupedContests.find((group) =>
          group.contests.some((contest) => contest.uuid === selectedContest)
        );
        const selectedContestGenders = selectedGroup?.genders || [];

        // Si solo hay un género disponible, seleccionarlo automáticamente
        if (selectedContestGenders.length === 1 && !selectedGender) {
          setTimeout(() => {
            handleGenderSelect(selectedContestGenders[0]);
          }, 0);
        }

        return (
          <div className="px-4">
            <h3 className="text-lg font-medium mb-4 text-gray-900 dark:text-white">
              Selecciona tu género
            </h3>
            <div className="space-y-2">
              {Object.values(GenderEnum).map(
                (gender) =>
                  selectedContestGenders.includes(gender) && (
                    <button
                      key={gender}
                      onClick={() => handleGenderSelect(gender)}
                      className={`w-full p-4 text-left rounded-lg transition-colors ${
                        selectedGender === gender
                          ? "bg-blue-500 text-white"
                          : "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                      }`}
                    >
                      <GenderBadge
                        text={translateGender(gender as GenderEnum)}
                        gender={gender}
                      />
                    </button>
                  )
              )}
            </div>
          </div>
        );
      }
      case 2: {
        const selectedContestData = data?.contests?.find(
          (contest) => contest.uuid === selectedContest
        );
        const isGroupedContest = groupedContests.some(
          (group) =>
            group.contests.length > 1 &&
            group.contests.some((contest) => contest.uuid === selectedContest)
        );
        let filteredAgeGroups: AgeGroupDto[] = [];
        if (isGroupedContest) {
          const originalContest = data?.contests?.find(
            (contest) =>
              selectedGender &&
              contest.genders.includes(selectedGender) &&
              selectedContestData?.name &&
              contest.name.includes(selectedContestData.name.split(" ")[0])
          );
          if (originalContest) {
            filteredAgeGroups = originalContest.age_groups.filter(
              (group) => group.gender === selectedGender
            );
          }
        }
        if (filteredAgeGroups.length === 0) {
          filteredAgeGroups =
            selectedContestData?.age_groups?.filter(
              (group) => group.gender === selectedGender
            ) || [];
        }

        // Si solo hay una categoría, redirigir automáticamente
        if (filteredAgeGroups.length === 1) {
          setTimeout(() => {
            handleClick(filteredAgeGroups[0]);
          }, 0);
          return <LoadingScreen />;
        }

        console.log("Carrera agrupada:", isGroupedContest);
        console.log("Género seleccionado:", selectedGender);
        console.log("Grupos filtrados:", filteredAgeGroups);
        return (
          <div className="px-4">
            <h3 className="text-lg font-medium mb-4 text-gray-900 dark:text-white">
              Selecciona tu categoría
            </h3>
            <List className="age-groups-list">
              {filteredAgeGroups?.map((ageGroup) => (
                <List.Item
                  key={ageGroup.uuid}
                  onClick={() => handleClick(ageGroup)}
                  arrowIcon={<div></div>}
                >
                  <GenderBadge
                    text={
                      <div className="flex flex-col">
                        <div className="text-[10px] dark:text-gray-400 text-gray-500">
                          {translateGender(ageGroup.gender as GenderEnum)}
                        </div>
                        <div className="font-[400] -mt-0.5 text-gray-900 dark:text-white">
                          {ageGroup.name}
                        </div>
                      </div>
                    }
                    gender={ageGroup.gender as GenderEnum}
                  />
                </List.Item>
              ))}
            </List>
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className="pb-9">
      <PullToRefresh onRefresh={async () => run()}>
        <div className="mb-6">
          <Steps current={currentStep}>
            <Steps.Step title="Carrera" />
            <Steps.Step title="Género" />
            <Steps.Step title="Categoría" />
          </Steps>
          {(selectedContest || selectedGender) && (
            <div className="mt-4 px-4 py-2 bg-gray-50 dark:bg-gray-900 rounded-lg">
              <div className="flex flex-wrap gap-2 text-sm">
                {selectedContest && (
                  <button
                    onClick={() => setCurrentStep(0)}
                    className="px-2 py-1 bg-gray-200 dark:bg-gray-700 rounded text-gray-900 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                  >
                    {groupedContests.find((group) =>
                      group.contests.some((c) => c.uuid === selectedContest)
                    )?.name ||
                      data?.contests?.find((c) => c.uuid === selectedContest)
                        ?.name}
                  </button>
                )}
                {selectedGender && (
                  <button
                    onClick={() => setCurrentStep(1)}
                    className="px-2 py-1 bg-gray-200 dark:bg-gray-700 rounded text-gray-900 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                  >
                    {translateGender(selectedGender)}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        {renderStep()}
        {currentStep > 0 && !(currentStep === 1 && groupedContests.length === 1) && (
          <div className="px-4 mt-4">
            <button
              onClick={() => {
                setCurrentStep((prev) => {
                  if (prev === 2) {
                    setSelectedGender(undefined);
                  } else if (prev === 1) {
                    setSelectedContest(undefined);
                  }
                  return prev - 1;
                });
              }}
              className="w-full p-3 text-center rounded-lg bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white"
            >
              Regresar
            </button>
          </div>
        )}
      </PullToRefresh>
    </div>
  );
};

const styles = `
  .age-groups-list .adm-list-body {
    border: none;
  }
`;

export default AgeGroupList;
