export enum PaceTypeEnum {
    MIN100M = "min/100m", // swimming
    MINKM = "min/km", // running
    MIN500M = "min/500m", // rowing
    KMH = "km/h" // cycling
  }
  
  export enum PaceSportTypeEnum {
    SWIMMING = PaceTypeEnum.MIN100M,
    RUNNING = PaceTypeEnum.MINKM,
    ROWING = PaceTypeEnum.MIN500M,
    CYCLING = PaceTypeEnum.KMH
  }

  // indica en que deporte se aplica el ritmo apartir de este dato de entrada min/100m y en español quiero que diga natacion
  export enum PaceSportTypeEnumES {
    SWIMMING = "Natación",
    RUNNING = "Carrera",
    ROWING = "Remo",
    CYCLING = "Ciclismo"
  }
    
  export function translatePaceType(paceType: PaceTypeEnum): string {
    switch (paceType) {
      case PaceTypeEnum.MIN100M:
        return PaceSportTypeEnumES.SWIMMING;
      case PaceTypeEnum.MINKM:
        return PaceSportTypeEnumES.RUNNING;
      case PaceTypeEnum.MIN500M:
        return PaceSportTypeEnumES.ROWING;
      case PaceTypeEnum.KMH:
        return PaceSportTypeEnumES.CYCLING;
      default:
        return paceType;
    }
  }
    