import React, { useState } from "react";
import { Button, Popup, List } from "antd-mobile";
import { MoreOutline } from "antd-mobile-icons";
import { useTheme } from "@/context/ThemeContext";

const MenuButton: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const isDark = theme === "dark";

  const handleToggleTheme = () => {
    toggleTheme();
    setVisible(false);
  };

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        className="!px-2"
        fill="none"
        style={{
          "--background-color": "transparent",
        }}
      >
        <MoreOutline
          className="text-gray-600 dark:text-gray-300"
          fontSize={24}
        />
      </Button>

      <Popup
        visible={visible}
        onMaskClick={() => setVisible(false)}
        position="right"
        maskStyle={{
          background: "rgba(0, 0, 0, 0.75)",
        }}
        bodyStyle={{
          width: "60vw",
          maxWidth: "250px",
          height: "100vh",
          padding: 0,
          backgroundColor: "var(--adm-color-background)",
        }}
      >
        <div className="flex flex-col h-full">
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <h2 className="text-lg font-medium dark:text-white">Menú</h2>
          </div>
          <List>
            <List.Item
              onClick={handleToggleTheme}
              prefix={
                <span className="text-lg mr-2">{isDark ? "☀️" : "🌙"}</span>
              }
            >
              <span className="dark:text-white">
                Modo {isDark ? "Claro" : "Oscuro"}
              </span>
            </List.Item>
          </List>
        </div>
      </Popup>
    </>
  );
};

export default MenuButton;
