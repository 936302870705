import React from "react";

interface GenderBadgeProps {
  text: any;
  gender: "male" | "female" | "relay" | "mixed" | Array<"male" | "female" | "relay" | "mixed">;
}

const GenderBadge: React.FC<GenderBadgeProps> = ({ text, gender }) => {
  const getColor = (g: "male" | "female" | "relay" | "mixed") => {
    switch (g) {
      case "male":
        return "#1677ff";
      case "female":
        return "#ff4d94";
      case "relay":
        return "#722ed1";
      case "mixed":
        return "#fa8c16";
      default:
        return "#999999";
    }
  };

  const genderArray = Array.isArray(gender) ? gender : [gender];

  return (
    <div className="flex items-center overflow-hidden text-ellipsis whitespace-nowrap w-full">
      <div className="flex space-x-2 mr-2">
        {genderArray.map((g, index) => (
          <div 
            key={index} 
            className="w-1.5 h-1.5 rounded-full" 
            style={{ backgroundColor: getColor(g) }}
          />
        ))}
      </div>
      <div className="flex-1 truncate">{text}</div>
    </div>
  );
};

export default GenderBadge;
