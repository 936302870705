import React from "react";
import { NavBar } from "antd-mobile";
import { useHeader } from "@/context/HeaderContext";
import { useNavigate, useLocation } from "react-router-dom";
import MenuButton from "@/components/MenuButton";
import SearchButton from "@/components/SearchButton";

const Header: React.FC = () => {
  const { title, back, live } = useHeader();
  const navigate = useNavigate();
  const location = useLocation();
  const showSearch = location.pathname !== "/";

  return (
    <>
      <div className="h-12"></div> {/* Placeholder fijo para evitar saltos */}
      <div
        className="fixed top-0 left-0 right-0 h-12 bg-white dark:bg-gray-900 shadow-sm"
        style={{
          zIndex: 1000,
        }}
      >
        <NavBar
          back={back ? true : null}
          onBack={() => navigate(back || "/")}
          right={
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              {showSearch && <div style={{ marginRight: '8px' }}><SearchButton /></div>}
              <div><MenuButton /></div>
            </div>
          }
        >
          <div className="flex items-center justify-center">
            {live && title && (
              <span className="relative flex h-2 w-2 mr-2 flex-shrink-0">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
              </span>
            )}
            <div className="text-xl tracking-wide text-gray-800 dark:text-white mr-1 overflow-hidden text-ellipsis whitespace-nowrap">
              {title}
            </div>
          </div>
        </NavBar>
      </div>
    </>
  );
};

export default Header;
