import React, { useRef, useEffect } from "react";
import { SearchOutline, CloseOutline } from "antd-mobile-icons";

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onClear?: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  onClear,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Simple approach to focus on mount
    const timeoutId = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="flex items-center bg-gray-100 dark:bg-gray-700 rounded-lg px-4 py-1">
      <SearchOutline
        className="text-gray-500 dark:text-gray-400 mr-3"
        fontSize={24}
      />
      <input
        ref={inputRef}
        type="text"
        inputMode="search"
        enterKeyHint="search"
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        className="flex-1 bg-transparent border-none outline-none text-base sm:text-lg"
        placeholder="Buscar por nombre o número"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoFocus
        style={{ fontSize: "16px", lineHeight: "24px" }}
      />
      {value && (
        <CloseOutline
          className="text-gray-500 dark:text-gray-400 ml-3"
          fontSize={22}
          onClick={() => {
            if (onClear) {
              onClear();
            } else {
              onChange("");
            }

            // Refocus after clearing
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 50);
          }}
        />
      )}
    </div>
  );
};

export default SearchInput;
