import React from "react";
import { Collapse } from "antd-mobile";
import TimeComponent from "@/components/TimeComponent";
import { ParticipantResultDto } from "@/services/qr/dto/participant/participant.dto";
import Avatar from "@/components/Avatar";
import { getInitials } from "@/utils/get_initials";
import { SegmentContent } from "./SegmentContent";
import { PaceTypeEnum } from "@/enums/pace_type.emum";

interface SegmentTableProps {
  result: ParticipantResultDto;
  disablePadding?: boolean;
}

// Iconos SVG personalizados con herencia de color
const SwimmingIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`w-5 h-5 ${className}`}
    fill="currentColor"
    viewBox="0 0 30 30"
  >
    <path d="M 22.5 7 A 3.5 3.5 0 0 0 19 10.5 A 3.5 3.5 0 0 0 22.5 14 A 3.5 3.5 0 0 0 26 10.5 A 3.5 3.5 0 0 0 22.5 7 z M 11.017578 8 A 1.0001 1.0001 0 0 0 10.552734 8.1054688 L 4.5527344 11.105469 A 1.0001161 1.0001161 0 0 0 5.3496094 12.935547 L 5.3378906 12.945312 L 10.496094 11.40625 L 12.677734 14.853516 L 5.3476562 19.037109 C 5.8456562 19.375109 7.046 20.017578 9 20.017578 C 10.336 20.017578 11.679375 19.392922 13.234375 18.669922 C 15.105375 17.799922 17.222062 16.823484 19.789062 16.771484 L 17.082031 14.03125 A 1.0001 1.0001 0 0 0 17.052734 13.998047 L 11.730469 8.3164062 A 1.0001 1.0001 0 0 0 11.017578 8 z M 20 18.759766 C 17.728583 18.759766 15.828125 19.668172 14.078125 20.482422 C 12.328125 21.296672 10.728583 22.017578 9 22.017578 C 7.1680833 22.017578 5.8315146 21.559147 4.9511719 21.115234 C 4.0708291 20.671322 3.7109375 20.294922 3.7109375 20.294922 A 1.0012827 1.0012827 0 1 0 2.2890625 21.705078 C 2.2890625 21.705078 2.9291709 22.337757 4.0488281 22.902344 C 5.1684854 23.466931 6.8319167 24.017578 9 24.017578 C 11.271417 24.017578 13.171875 23.109172 14.921875 22.294922 C 16.671875 21.480672 18.271417 20.759766 20 20.759766 C 23.626667 20.759766 26.402344 22.800781 26.402344 22.800781 A 1.0001 1.0001 0 1 0 27.597656 21.199219 C 27.597656 21.199219 24.373333 18.759766 20 18.759766 z"></path>
  </svg>
);

const RunningIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`w-5 h-5 ${className}`}
    fill="currentColor"
    viewBox="0 0 30 30"
  >
    <path d="M 20.498047 3 C 19.118047 3 18 4.12 18 5.5 C 18 6.88 19.118047 8 20.498047 8 C 21.881047 8 23 6.88 23 5.5 C 23 4.12 21.881047 3 20.498047 3 z M 11 8 A 1.0001 1.0001 0 0 0 10.292969 8.2929688 L 7.2929688 11.292969 A 1 1 0 0 0 7 12 A 1 1 0 0 0 8 13 A 1 1 0 0 0 8.7070312 12.707031 A 1 1 0 0 0 8.7167969 12.697266 L 11.212891 10.201172 L 13.533203 10.587891 L 11.392578 14.154297 L 11.394531 14.162109 C 11.147531 14.550109 11 15.007 11 15.5 C 11 16.165 11.2645 16.764891 11.6875 17.212891 L 11.6875 17.222656 L 15.425781 21.085938 L 16 26 A 1 1 0 0 0 17 27 A 1 1 0 0 0 18 26 L 18 20 A 1.0001 1.0001 0 0 0 17.818359 19.425781 L 15.787109 16.523438 L 18.640625 11.792969 L 18.634766 11.783203 C 18.861766 11.406203 19 10.971 19 10.5 C 19 9.12 17.882 8.001 16.5 8 L 16.498047 8 L 11 8 z M 20.292969 11.875 L 19 14 L 19.216797 14.109375 L 20.492188 14.861328 A 1.0001 1.0001 0 0 0 21 15 L 25 15 A 1 1 0 0 0 26 14 A 1 1 0 0 0 25 13 L 21.654297 13 L 20.292969 11.875 z M 9.8710938 17.609375 L 8.3691406 19.402344 L 4 20 A 1 1 0 0 0 3 21 A 1 1 0 0 0 4 22 L 9 22 A 1.0001 1.0001 0 0 0 9.7207031 21.691406 L 9.7890625 21.619141 L 11.898438 19.699219 L 9.8710938 17.609375 z"></path>
  </svg>
);

const RowingIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`w-5 h-5 ${className}`}
    fill="currentColor"
    viewBox="0 0 30 30"
  >
    <path d="M 19.402344 2 L 15.302734 3.5683594 C 15.302734 3.5683594 15.771234 5.2014375 16.615234 6.6484375 C 17.243365 7.6947428 18.091391 8.2249246 19.013672 8.1113281 L 19.300781 8.9121094 L 14.46875 9.9863281 A 1.0001 1.0001 0 0 0 13.876953 10.373047 L 10.900391 14.455078 A 1.0001 1.0001 0 0 0 10.791016 14.648438 L 10.785156 14.652344 L 8.1855469 20.089844 L 9.6328125 20.089844 C 10.893576 20.089844 12.967656 20.660865 14.728516 21.113281 A 1.0001 1.0001 0 0 0 14.832031 21.146484 L 19.072266 22.244141 A 1.0001 1.0001 0 0 0 19.675781 22.212891 L 23.537109 20.763672 L 24.371094 23.091797 C 23.151379 23.549912 21.560329 24 20 24 C 18.412241 24 17.027445 23.54884 15.460938 23.046875 C 13.894429 22.54491 12.151095 22 10 22 C 5.697809 22 2.4492188 24.166016 2.4492188 24.166016 A 1.0001 1.0001 0 1 0 3.5507812 25.833984 C 3.5507812 25.833984 6.370191 24 10 24 C 11.814905 24 13.295368 24.45509 14.849609 24.953125 C 16.403851 25.45116 18.036759 26 20 26 C 23.926481 26 27.501953 23.865234 27.501953 23.865234 A 1.0001 1.0001 0 0 0 27.042969 21.994141 A 1.0001 1.0001 0 0 0 26.498047 22.134766 C 26.498047 22.134766 25.981557 22.395626 25.292969 22.701172 L 24.447266 20.337891 A 1.0001 1.0001 0 0 0 23.832031 18.617188 L 20.908203 10.435547 A 1.0001 1.0001 0 0 0 20.302734 8.7441406 L 19.941406 7.734375 C 20.530551 7.2803618 20.74979 6.5049321 20.556641 5.4414062 C 20.314641 3.7534063 19.402344 2 19.402344 2 z M 19.978516 10.808594 L 22.865234 18.880859 L 22.580078 18.988281 L 19.361328 19.654297 L 15.265625 18.527344 L 13.644531 15.943359 L 15.306641 12.363281 L 19.503906 10.914062 L 19.978516 10.808594 z M 17.5 13 A 2.5 2.5 0 0 0 15 15.5 A 2.5 2.5 0 0 0 17.5 18 A 2.5 2.5 0 0 0 20 15.5 A 2.5 2.5 0 0 0 17.5 13 z"></path>
  </svg>
);

const CyclingIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    className={`w-5 h-5 ${className}`}
    fill="currentColor"
  >
    <path d="M 18.5 3 A 2.5 2.5 0 0 0 16 5.5 A 2.5 2.5 0 0 0 18.5 8 A 2.5 2.5 0 0 0 21 5.5 A 2.5 2.5 0 0 0 18.5 3 z M 15.5 8 C 14.863206 8 14.227336 8.2432501 13.740234 8.7265625 C 13.739869 8.7269251 13.738647 8.7261996 13.738281 8.7265625 L 13.732422 8.7324219 L 9.7753906 12.691406 A 2.5 2.5 0 0 0 9.7304688 12.736328 L 9.6738281 12.792969 L 9.6738281 12.796875 A 2.5 2.5 0 0 0 9 14.5 A 2.5 2.5 0 0 0 10.189453 16.625 L 10.189453 16.630859 L 14.244141 19 L 14.009766 23.867188 A 1.0001 1.0001 0 1 0 15.984375 24.173828 L 16.984375 18.529297 A 1.0001 1.0001 0 0 0 16.703125 17.642578 L 16.572266 17.511719 L 14.070312 14.912109 L 16.878906 12.181641 L 19.125 14.505859 L 19.208984 14.613281 A 1.0001 1.0001 0 0 0 19.431641 14.822266 A 1.0001 1.0001 0 0 0 19.435547 14.824219 L 19.435547 14.826172 A 1.0001 1.0001 0 0 0 20 15 L 24 15 A 1.0001 1.0001 0 1 0 24 13 L 20.490234 13 L 20.140625 12.550781 L 17.392578 8.8671875 L 17.375 8.8671875 C 17.336777 8.822376 17.312676 8.7774732 17.267578 8.7324219 C 16.779078 8.2444219 16.139875 8 15.5 8 z M 7.5 18 C 5.0265633 18 3 20.026563 3 22.5 C 3 24.973437 5.0265633 27 7.5 27 C 9.9734367 27 12 24.973437 12 22.5 C 12 20.026563 9.9734367 18 7.5 18 z M 22.5 18 C 20.026563 18 18 20.026563 18 22.5 C 18 24.973437 20.026563 27 22.5 27 C 24.973437 27 27 24.973437 27 22.5 C 27 20.026563 24.973437 18 22.5 18 z M 7.5 20 C 8.8925565 20 10 21.107443 10 22.5 C 10 23.892557 8.8925565 25 7.5 25 C 6.1074435 25 5 23.892557 5 22.5 C 5 21.107443 6.1074435 20 7.5 20 z M 22.5 20 C 23.892557 20 25 21.107443 25 22.5 C 25 23.892557 23.892557 25 22.5 25 C 21.107443 25 20 23.892557 20 22.5 C 20 21.107443 21.107443 20 22.5 20 z"></path>
  </svg>
);

// Función para obtener el icono según el tipo de ritmo
function getSportIcon(paceType: PaceTypeEnum) {
  const iconClass = "text-gray-900 dark:text-white";

  switch (paceType) {
    case PaceTypeEnum.MIN100M: // Natación
      return <SwimmingIcon className={iconClass} />;
    case PaceTypeEnum.MINKM: // Carrera
      return <RunningIcon className={iconClass} />;
    case PaceTypeEnum.MIN500M: // Remo
      return <RowingIcon className={iconClass} />;
    case PaceTypeEnum.KMH: // Ciclismo
      return <CyclingIcon className={iconClass} />;
    default:
      return null;
  }
}

// Convertir distancia a kilómetros
function distanceToKm(distance: number): number {
  return distance / 1000; // Asegura que el resultado sea una cadena con dos decimales
}

const SegmentTable: React.FC<SegmentTableProps> = ({
  result,
  disablePadding,
}) => {
  const segments = result.segments;

  return (
    <>
      {segments.length > 0 && (
        <>
          {segments.length > 2 ? (
            <Collapse accordion>
              {segments.map((segment, index) => {
                const progress = result.percent_completed;
                return (
                  <Collapse.Panel
                    key={segment.uuid}
                    title={
                      <div className="grid gap-6 grid-cols-2 px-2">
                        <div className="flex flex-col items-start justify-center">
                          <div className="flex items-center w-full">
                            <Avatar
                              title={getInitials(segment.name)}
                              color="gray"
                              size="small"
                              circle={progress}
                              icon={
                                <span className="text-gray-900 dark:text-white">
                                  {getSportIcon(segment.pace_type)}
                                </span>
                              }
                            />

                            <h3 className="text-lg text-gray-800 dark:text-gray-200 ml-2 w-full max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
                              {distanceToKm(segment.distance)} km {segment.name}
                            </h3>
                          </div>
                        </div>
                        <div className="flex flex-col items-end justify-center text-lg text-gray-800 dark:text-gray-200">
                          <TimeComponent
                            time={segment.total_time}
                            color="currentColor"
                          />
                        </div>
                      </div>
                    }
                  >
                    <SegmentContent segment={segment} />
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          ) : (
            segments.map((segment, index) => (
              <div key={segment.uuid}>
                {segments.length > 1 && (
                  <div className="grid grid-cols-2 py-4 px-4">
                    <div className="flex flex-col items-start justify-center">
                      <h3 className="text-lg text-gray-800 dark:text-gray-200">
                        {distanceToKm(segment.distance)} km {segment.name}
                      </h3>
                    </div>
                    <div className="flex flex-col items-end justify-center text-lg text-gray-800 dark:text-gray-200">
                      <TimeComponent
                        time={segment.total_time}
                        color="currentColor"
                      />
                    </div>
                  </div>
                )}
                <div className={disablePadding ? "" : "px-4"}>
                  <SegmentContent segment={segment} />
                </div>
              </div>
            ))
          )}
        </>
      )}
    </>
  );
};

export default SegmentTable;
