import React from "react";

interface AvatarProps {
  title?: string;
  titleSize?: string;
  size?: "extrasmall" | "small" | "medium" | "large";
  dot?: boolean;
  color?:
    | "gray"
    | "blue"
    | "red"
    | "green"
    | "yellow"
    | "pink"
    | "female"
    | "male"
    | "mixed"
    | "relay";
  circle?: number; // Valor de 0 a 100
  tag?: string;
  icon?: React.ReactNode;
}

export default function Avatar({
  size = "medium",
  title = "",
  titleSize = "14px",
  dot = false,
  color = "blue", // Por defecto, se usa "blue"
  circle = 0, // Por defecto, 0 significa que no hay relleno en el círculo
  tag = "",
  icon = null,
}: Readonly<AvatarProps>) {
  const colorConfig = {
    blue: {
      bgColor: "bg-blue-100 dark:bg-blue-900",
      fillColor: "#3B82F6",
      textColor: "text-blue-900 dark:text-blue-100"
    },
    red: {
      bgColor: "bg-red-100 dark:bg-red-900",
      fillColor: "#EF4444",
      textColor: "text-red-900 dark:text-red-100"
    },
    green: {
      bgColor: "bg-green-100 dark:bg-green-900",
      fillColor: "#10B981",
      textColor: "text-green-900 dark:text-green-100"
    },
    yellow: {
      bgColor: "bg-yellow-100 dark:bg-yellow-900",
      fillColor: "#F59E0B",
      textColor: "text-yellow-900 dark:text-yellow-100"
    },
    pink: {
      bgColor: "bg-pink-100 dark:bg-pink-900",
      fillColor: "#EC4899",
      textColor: "text-pink-900 dark:text-pink-100"
    },
    female: {
      bgColor: "bg-pink-100 dark:bg-pink-900",
      fillColor: "#EC4899",
      textColor: "text-pink-900 dark:text-pink-100"
    },
    male: {
      bgColor: "bg-blue-100 dark:bg-blue-900",
      fillColor: "#3B82F6",
      textColor: "text-blue-900 dark:text-blue-100"
    },
    gray: {
      bgColor: "bg-gray-100 dark:bg-gray-800",
      fillColor: "#6B7280",
      textColor: "text-gray-900 dark:text-gray-100"
    },
    mixed: {
      bgColor: "bg-gray-100 dark:bg-gray-800",
      fillColor: "#6B7280",
      textColor: "text-gray-900 dark:text-gray-100"
    },
    relay: {
      bgColor: "bg-gray-100 dark:bg-gray-800",
      fillColor: "#6B7280",
      textColor: "text-gray-900 dark:text-gray-100"
    },
  };

  const config = colorConfig[color];

  const sizeClasses = {
    extrasmall: "w-7 h-7",
    small: "w-9 h-9",
    medium: "w-10 h-10",
    large: "w-12 h-12",
  };

  const radiusMap = {
    extrasmall: 14,
    small: 16,
    medium: 18,
    large: 24,
  };

  const svgSizeMap = {
    extrasmall: 32,
    small: 36,
    medium: 40,
    large: 48,
  };

  const radius = radiusMap[size];
  const svgSize = svgSizeMap[size];
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (circle / 100) * circumference;

  // Función para clonar el icono y añadir clases
  const renderIcon = () => {
    if (!icon) return null;

    if (React.isValidElement(icon)) {
      return React.cloneElement(icon as React.ReactElement, {
        className: `text-gray-900 dark:text-white ${
          icon.props.className || ""
        }`,
      });
    }

    return <span className="text-gray-900 dark:text-white">{icon}</span>;
  };

  return (
    <div className="relative flex items-center">
      <span
        className={`rounded-full ${sizeClasses[size]} ${config.bgColor} flex items-center justify-center`}
        style={{ fontSize: titleSize }}
      >
        {icon ? (
          renderIcon()
        ) : (
          <span className={config.textColor}>{title}</span>
        )}
      </span>
      {tag && (
        <span className="absolute z-10 -bottom-1 -right-1 bg-red-500 text-white text-[8px] px-1 rounded">
          {tag}
        </span>
      )}
      {circle > 0 && (
        <svg
          className="absolute inset-0"
          width={svgSize}
          height={svgSize}
          viewBox={`0 0 ${svgSize} ${svgSize}`}
        >
          <circle
            stroke="currentColor"
            strokeWidth="2"
            fill="transparent"
            r={radius}
            cx="50%"
            cy="50%"
            className="text-gray-200 dark:text-gray-700"
          />
          <circle
            stroke="currentColor"
            strokeWidth="2"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            fill="transparent"
            r={radius}
            cx="50%"
            cy="50%"
            style={{
              stroke: config.fillColor,
              transformOrigin: "50% 50%",
              transform: "rotate(-90deg)", // Rotar en sentido antihorario para iniciar desde la derecha -290
            }}
          />
        </svg>
      )}
      {dot && (
        <div>
          <span className="absolute bottom-[-1.5px] right-[0.5px] inline-flex rounded-full h-[12px] w-[12px] bg-white"></span>
          <span className="absolute bottom-[0px] right-0.5 flex h-[8px] w-[8px] flex-shrink-0">
            <span className="absolute inline-flex h-full w-full rounded-full bg-white"></span>
            <span
              className="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
              style={{ backgroundColor: config.fillColor }}
            ></span>
            <span
              className="relative inline-flex rounded-full h-[8px] w-[8px]"
              style={{ backgroundColor: config.fillColor }}
            ></span>
          </span>
        </div>
      )}
    </div>
  );
}
