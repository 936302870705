import { ApiRequest } from "@/services/request";
import { PaginatedAgeGroupDto } from "@/services/qr/dto/age_group/age_group.dto";

const { REACT_APP_PAGE_SIZE } = process.env;
export function getAgeGroupResultsRequest(
  age_group: string,
  lastEvaluatedKey?: any
): Promise<PaginatedAgeGroupDto> {
  return ApiRequest(`/age_group/${age_group}`, {
    method: "POST",
    body: JSON.stringify({
      limit: Number(REACT_APP_PAGE_SIZE),
      last_evaluated_key: lastEvaluatedKey,
    }),
  });
}
