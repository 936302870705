import React, { useEffect, useState, useCallback } from "react";
import { PullToRefresh } from "antd-mobile";
import { useRequest } from "ahooks";
import { useNavigate } from "react-router-dom";
import { getEventsRequest } from "@/services/qr/event.service";
import { EventDto } from "@/services/qr/dto/event/event.dto";
import { useHeader } from "@/context/HeaderContext";
import { getInitials } from "@/utils/get_initials";
import { CustomInfiniteScroll } from "@/components/CustomInfiniteScroll";
import LoadingScreen from "@/components/LoadingScreen";

const { REACT_APP_TITLE } = process.env;

const EventList: React.FC = () => {
  const navigate = useNavigate();
  const { setTitle } = useHeader();
  const [events, setEvents] = useState<EventDto[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const { loading, run } = useRequest(
    () => getEventsRequest(lastEvaluatedKey),
    {
      onSuccess: (data) => {
        setEvents((prevEvents) => {
          const allEvents = [...prevEvents, ...data.events];
          const uniqueEvents = allEvents.filter(
            (event, index, self) =>
              index === self.findIndex((e) => e.uuid === event.uuid)
          );
          return uniqueEvents;
        });

        if (data.last_evaluated_key) {
          setLastEvaluatedKey(data.last_evaluated_key);
        }

        setHasMore(!!data.last_evaluated_key);
        setIsLoadingMore(false);
      },
      onError: (error) => {
        console.error("Error fetching events:", error);
        setHasMore(false);
        setIsLoadingMore(false);
      },
    }
  );

  const handleLoadMore = useCallback((): void => {
    if (!loading && !isLoadingMore && hasMore) {
      setIsLoadingMore(true);
      run();
    }
  }, [loading, isLoadingMore, hasMore, run]);

  const handleClick = (event: EventDto) => {
    navigate(`/${event.prefix}`);
  };

  useEffect(() => {
    setTitle(REACT_APP_TITLE?.toString() || "");
  }, [setTitle]);

  return (
    <div className="p-4">
      <PullToRefresh
        onRefresh={async () => {
          setEvents([]);
          setLastEvaluatedKey(null);
          setIsLoadingMore(false);
          run();
        }}
      >
        <CustomInfiniteScroll
          loadMore={handleLoadMore}
          hasMore={hasMore}
          isLoading={isLoadingMore}
          threshold={300}
        >
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {loading && events.length === 0 && (
              <div className="col-span-full">
                <LoadingScreen />
              </div>
            )}
            
            {events.map((event) => (
              <div
                key={event.uuid}
                onClick={() => handleClick(event)}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow cursor-pointer"
              >
                <div className="relative">
                  <div className="aspect-square flex items-center justify-center bg-gray-100 dark:bg-gray-700">
                    <span
                      className={`text-3xl font-bold ${
                        event.live
                          ? "text-white"
                          : "text-gray-500 dark:text-gray-300"
                      }`}
                    >
                      {getInitials(event.name)}
                    </span>
                  </div>
                  {event.live && (
                    <div className="absolute top-2 right-2">
                      <span className="px-2 py-1 text-xs font-semibold text-white bg-red-500 rounded-full">
                        LIVE
                      </span>
                    </div>
                  )}
                </div>
                <div className="p-3 space-y-1">
                  <h3 className="font-medium text-gray-900 dark:text-white line-clamp-2">
                    {event.name}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {event.start_date}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </CustomInfiniteScroll>
      </PullToRefresh>
    </div>
  );
};

export default EventList;
