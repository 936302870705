import React, { useEffect, useRef, useCallback, ReactNode, useState } from 'react';
import { DotLoading } from 'antd-mobile';

interface CustomInfiniteScrollProps {
  loadMore: () => void;
  hasMore: boolean;
  isLoading?: boolean;
  children: ReactNode;
  threshold?: number;
  loadingComponent?: ReactNode;
  debounceTime?: number;
  minWindowHeight?: number;
}

/**
 * Componente de scroll infinito personalizado que mantiene la posición del scroll
 * al cargar más contenido.
 */
export const CustomInfiniteScroll: React.FC<CustomInfiniteScrollProps> = ({
  loadMore,
  hasMore,
  isLoading = false,
  children,
  threshold = 300,
  loadingComponent = <DotLoading />,
  debounceTime = 500,
  minWindowHeight = 500,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);

  // Manejador de scroll personalizado
  const handleScroll = useCallback(() => {
    if (!containerRef.current || !hasMore || loadingRef.current || isLoading || !canLoadMore) return;

    const scrollElement = window.document.documentElement;
    const scrollTop = scrollElement.scrollTop;
    const clientHeight = scrollElement.clientHeight;
    const scrollHeight = scrollElement.scrollHeight;
    
    // Si la ventana es demasiado pequeña, no cargar automáticamente
    if (window.innerHeight < minWindowHeight && scrollTop === 0) {
      return;
    }
    
    // Calcular si estamos cerca del final del scroll
    const isNearBottom = scrollTop + clientHeight + threshold >= scrollHeight;
    
    if (isNearBottom) {
      loadingRef.current = true;
      setCanLoadMore(false);
      
      // Usar un debounce para evitar múltiples cargas
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      
      loadMore();
      
      // Establecer un tiempo mínimo entre cargas
      timeoutRef.current = setTimeout(() => {
        setCanLoadMore(true);
      }, debounceTime);
    }
  }, [hasMore, isLoading, loadMore, threshold, canLoadMore, minWindowHeight, debounceTime]);

  // Resetear el estado de carga cuando cambia isLoading a false
  useEffect(() => {
    if (!isLoading) {
      loadingRef.current = false;
    }
  }, [isLoading]);

  // Limpiar el timeout cuando el componente se desmonta
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Configurar el detector de scroll
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    // Verificar si necesitamos cargar más contenido al montar el componente
    // pero solo si el contenido no llena la pantalla y hay espacio para más
    const checkInitialLoad = () => {
      const scrollElement = window.document.documentElement;
      const clientHeight = scrollElement.clientHeight;
      const scrollHeight = scrollElement.scrollHeight;
      
      // Si el contenido es menor que la altura de la ventana y tenemos más contenido para cargar
      if (scrollHeight <= clientHeight && hasMore && !isLoading && !loadingRef.current && canLoadMore) {
        // Solo cargar más si la ventana es lo suficientemente alta
        if (window.innerHeight >= minWindowHeight) {
          loadingRef.current = true;
          setCanLoadMore(false);
          loadMore();
          
          timeoutRef.current = setTimeout(() => {
            setCanLoadMore(true);
          }, debounceTime);
        }
      }
    };
    
    // Ejecutar después de que el DOM se haya actualizado
    setTimeout(checkInitialLoad, 100);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, hasMore, isLoading, loadMore, canLoadMore, minWindowHeight, debounceTime]);

  return (
    <div ref={containerRef}>
      {children}
      {hasMore && (
        <div className="p-4 text-center">
          {isLoading ? loadingComponent : null}
        </div>
      )}
    </div>
  );
};
