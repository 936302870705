import { ApiRequest } from "@/services/request";
import {
  PaginatedEventsDto,
  EventDto,
} from "@/services/qr/dto/event/event.dto";

const { REACT_APP_PAGE_SIZE } = process.env;
// get event by prefix
export function getEventRequest(prefix: string): Promise<EventDto> {
  return ApiRequest(`/event/${prefix}`, {
    method: "GET",
  });
}

// get events via post request if not last_evaluated_key not send
export function getEventsRequest(
  lastEvaluatedKey?: any
): Promise<PaginatedEventsDto> {
  return ApiRequest(`/events`, {
    method: "POST",
    body: JSON.stringify({
      limit: Number(REACT_APP_PAGE_SIZE),
      last_evaluated_key: lastEvaluatedKey,
    }),
  });
}
